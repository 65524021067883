import React from "react";
import PageTransition from "gatsby-plugin-page-transitions";
import { Jumbotron, Container, Row, Col, Image } from "react-bootstrap";
import Layout from "../components/Layout/Layout";
import Section from "../components/Section/Section";
import SEO from "../components/Seo/Seo";
import { P, H1, H2, H5 } from "../components/Typography/Typography";
import logo from "../images/logo.svg";
import chatBubble from "../images/sf-chat-bubble.png";
import fbGroup from "../images/sf-fb-group.png";
import youtube from "../images/sf-youtube.png";

const TalksPage = () => (
  <PageTransition>
    <Layout>
      <SEO title="Talks by Softbinator" />
      <Container>
        <Jumbotron
          as="header"
          className="sf-what-we-do d-flex align-items-center"
        >
          <Row>
            <Col lg={8}>
              <H1>What we do</H1>
              <P>
                With a focus on uniting developers around the country in a
                community where people can connect and keep on learning we are
                also investing in start-ups that help’s Romania digitalisations
                in education, health system & innovation.
              </P>
            </Col>
          </Row>
        </Jumbotron>
      </Container>

      <Section className="sf-talks-softbinator">
        <Container>
          <Row>
            <Col>
              <H2>Talks by Softbinator</H2>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={3}>
              <Image
                src={chatBubble}
                className="img-fluid chat-bubble"
                alt=""
              />
            </Col>
            <Col md={9}>
              <P>
                We created an event named “Talks” with purpose to gather awesome
                people on a biweekly basis, to talk about what’s new or
                interesting in IT. The concept was started in February 2013, and
                since then, we couldn’t stop. Usually, there are two speakers
                per event who, for 30 minutes talk about their ideas, new gizmos
                or technologies, solutions for common yet difficult issues,
                followed by a short QA session.
              </P>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={8}>
              <P>
                We dedicated a Facebook group especially for “Talks”, where we
                announce the events, details about the speaker and their
                presentations. Also we are posting quality content and of course
                who want to publish interesting and helpful articles, news or
                things related with the IT field, is welcomed to do that if the
                posts are relevant for the community.
              </P>
            </Col>
            <Col md={4}>
              <div className="fb-group d-flex justify-content-center align-items-center">
                <Image src={fbGroup} alt="" />
                <div>
                  <p>Talks by Softbinator</p>
                  <a
                    href="https://www.facebook.com/groups/talks.by.softbinator"
                    title="Join our Facebook Group"
                  >
                    Join our Facebook Group
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={4}>
              <div className="youtube-channel d-flex justify-content-center align-items-center">
                <Image src={youtube} alt="" />
                <div>
                  <p>Softbinator Foundation</p>
                  <a
                    href="https://www.youtube.com/user/softbinator"
                    title="Subscribe to our videos"
                  >
                    Subscribe to our videos
                  </a>
                </div>
              </div>
            </Col>
            <Col md={8}>
              <P>
                After every event, we upload the talk on our{" "}
                <strong>YouTube channel</strong>, so you can watch it, even if
                you cannot attend the event in real time.
              </P>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-quote">
        <Container>
          <Row>
            <Col className="d-flex justify-content-center mb-4">
              <Image src={logo} alt="" />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={9}>
              <H5 className="text-center">
                Our belief is that programming is more than writing lines of
                code! To us, it’s an art that keeps growing and that has a lot
                to say. So, let’s get the tech talks going!
              </H5>
            </Col>
          </Row>
        </Container>
      </Section>

      <Section className="sf-brand">
        <Container>
          <Row className="mt-3 mb-3 justify-content-center">
            <Col md={7}>
              <P className="text-center">
                "Talks by Softbinator" events were initially organized in
                Bucharest and since 2019, have been extended to other cities
                like Brasov and Constanta. The Foundation has full rights over
                the <strong>"Talks by Softbinator"</strong> brand.
              </P>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  </PageTransition>
);

export default TalksPage;
